import React from 'react';
import classNames from 'classnames/bind';
import style from './Link.module.scss';

const cx = classNames.bind(style);

type LinkProps = {
  children: React.ReactNode;
  href: string;
  className?: string;
};

export function Link({ children, href, ...props }: LinkProps): JSX.Element {
  const LinkClasses = cx(
    {
      Link: true,
    },
    props.className
  );

  return (
    <a href={href} className={LinkClasses}>
      {children}
    </a>
  );
}
