import React from 'react';
import { isExpired } from 'react-jwt';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { selectIsAuthenticated, selectToken } from '../../../store/reducers';

type ProtectedPageProps = {
  children: React.ReactElement;
};

export function ProtectedPage({ children }: ProtectedPageProps) {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const token = useSelector(selectToken);
  const location = useLocation();

  if (!isAuthenticated || !token || isExpired(token)) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return children;
}
