import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import classNames from 'classnames/bind';
import { store } from './store/store';
import { Router } from './router';
import 'react-toastify/dist/ReactToastify.css';
import style from './App.module.scss';

const cx = classNames.bind(style);

export function App() {
  const appClasses = cx({ App: true });

  return (
    <Provider store={store}>
      <ToastContainer autoClose={5000} position={'top-right'} />
      <div className={appClasses}>
        <Router />
      </div>
    </Provider>
  );
}
