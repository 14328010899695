import { IconDefaults } from '../IconDefaults';
import type { IconProps } from '../IconProps';

export function AccountPlus({ size = IconDefaults.size, color = IconDefaults.color }: IconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17.91 17.93' width={size} height={size}>
      <g id='a' />
      <g id='b'>
        <g id='c'>
          <g id='d'>
            <g id='e'>
              <g>
                <path fill={color} d='M17.91,14.56h-3.45v3.37h-.82v-3.37h-3.52v-.82h3.51v-3.5h.82v3.5h3.45v.82Z' />
                <path
                  fill={color}
                  d='M3.37,4.92C3.37,2.14,5.65-.02,8.33,0c2.69,.02,4.89,2.23,4.88,4.93,0,2.73-2.24,4.94-4.97,4.92-2.68-.02-4.88-2.23-4.88-4.93Zm9.03,.01c0-2.25-1.83-4.09-4.09-4.11-2.26-.01-4.11,1.82-4.12,4.09-.01,2.26,1.82,4.1,4.09,4.12,2.26,.01,4.11-1.82,4.12-4.1Z'
                />
                <path
                  fill={color}
                  d='M10.75,10.51l-.15,.8c-.19-.03-.37-.05-.56-.08-.62-.07-1.24-.09-1.87-.04-.65,.05-1.28,.17-1.9,.36-1.17,.37-2.2,.98-3.06,1.85-1.09,1.1-1.84,2.39-2.25,3.89-.05,.2-.09,.41-.14,.62l-.83-.09c.09-.37,.16-.73,.27-1.08,.53-1.69,1.46-3.14,2.78-4.31,.94-.83,2.02-1.39,3.22-1.73,1.01-.29,2.05-.41,3.1-.35,.42,.02,.84,.09,1.26,.13,.03,0,.06,.01,.11,.02Z'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
